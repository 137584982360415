/**
 * The PopperController makes it possible to create a popper (using PopperJS)
 * from HTML with some data attributes. It requires two sub elements:
 *
 * 1. A button (data-popper-target="button") to toggle the state of the popper.
 * 2. Some content (data-popper-target="content") for what to show in the popper.
 *
 * The content element is expected to be hidden with the class "hidden".
 */
import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";
import { useClickOutside } from "stimulus-use";
import { useHotkeys } from "stimulus-use/hotkeys";

export default class extends Controller {
  static targets = ["button", "content"];
  static values = {
    parentSelector: String,
    placement: String,
  };

  opened = false;
  popperElement = null;

  connect() {
    useClickOutside(this);
    useHotkeys(this, {
      esc: [this.close],
    });
  }

  async open() {
    this.opened = true;

    const popperContainer = document.createElement("div");
    popperContainer.innerHTML = this.contentTarget.innerHTML;

    const popper = popperContainer.firstChild;
    popper.classList.remove("hidden");
    popper.classList.add("z-50");

    const parent = document.querySelector(this.parentSelectorValue || "body");
    parent.prepend(popper);
    this.popperElement = popper;

    createPopper(this.buttonTarget, popper, {
      placement: this.placementValue || "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 5],
          },
        },
      ],
    });

    popper.addEventListener("click", this.close.bind(this));
  }

  async close(event) {
    // Prevent closing if the click outside event is on the popper itself.
    if (
      event &&
      this.popperElement?.contains(event.detail?.originalEvent?.target)
    ) {
      return;
    }

    setTimeout(() => {
      this.popperElement?.remove();
      this.opened = false;
    }, 0);
  }

  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }
}
