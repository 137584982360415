import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import debounce from "lodash/debounce";

const ignoredKeys = ["authenticity_token", "search_terms"];

export default class extends Controller {
  static targets = ["form"];

  initialize() {
    this.query = debounce(this.query, 250);
  }

  query() {
    document.getElementById("page").value = "1";

    const url = new URL(window.location.href);
    const formData = new FormData(this.formTarget);
    ignoredKeys.forEach((key) => {
      formData.delete(key);
    });
    url.search = new URLSearchParams(formData).toString();

    // This is a hack and could break if Turbo changes its internal API.
    // Without it, Turbo doesn't know what to do on going forward or back in
    // history after this action. It would be best to use Turbo.visit
    // directly, but it causes problems with focus on the search field.
    Turbo.navigator.history.replace(url);

    this.cleanCommand();
    this.formTarget.requestSubmit();
  }

  command(e) {
    if (e.params.confirm) {
      const confirmed = window.confirm(e.params.confirm);
      if (!confirmed) return;
    }

    const inputCommand = document.createElement("input");
    inputCommand.type = "hidden";
    inputCommand.value = e.params.command;
    inputCommand.name = "command";
    this.formTarget.appendChild(inputCommand);

    const inputResource = document.createElement("input");
    inputResource.type = "hidden";
    inputResource.value = e.params.resource;
    inputResource.name = "resource";
    this.formTarget.appendChild(inputResource);

    this.formTarget.requestSubmit();
    this.cleanCommand();
  }

  modal(e) {
    this.dispatch("open-modal", { detail: { id: e.params.modal } });
  }

  clearQuery() {
    this.formTarget.querySelector("[name=query]").value = "";
    this.query();
  }

  cleanCommand() {
    const inputCommand = this.formTarget.querySelector('input[name="command"]');
    inputCommand && inputCommand.remove();
    const inputResource = this.formTarget.querySelector(
      'input[name="resource"]',
    );
    inputResource && inputResource.remove();
  }
}
