import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";
import { useClickOutside } from "stimulus-use";
import { useHotkeys } from "stimulus-use/hotkeys";

export default class extends Controller {
  static targets = ["backdrop", "modal"];
  static values = {
    id: String,
  };

  initialize() {
    if (!this.backdropTarget || !this.modalTarget) {
      throw new Error("Missing targets");
    }

    useClickOutside(this, { element: this.modalTarget });

    useHotkeys(this, {
      esc: [this.close],
    });
  }

  clickOutside(e) {
    e.preventDefault();
    this.close();
  }

  open({ detail: { id } }) {
    if (id !== this.idValue) {
      return;
    }

    this.enter();
  }

  close() {
    this.leave();
  }

  enter() {
    this.element.classList.remove("hidden");

    return Promise.all([enter(this.backdropTarget), enter(this.modalTarget)]);
  }

  leave() {
    return Promise.all([
      leave(this.backdropTarget),
      leave(this.modalTarget),
    ]).then(() => {
      this.element.classList.add("hidden");
    });
  }
}
